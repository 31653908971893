import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setUserLogout } from '../user/UserAction.js';
import { strings } from '../../resources/locales/i18n.js';
import Routing from 'src/modules/routing/Routing';
import { Link } from 'react-router-dom';
import { clearMenuState, setMenuState } from '../drawer/DrawerActions.js'
import UserManager from '../user/UserManager'
import {TypeUsers} from "../backOffice/BackOfficeManager";
class DrawerContainer extends Component {
  state = {
    xClickPosition: null
  }
  componentDidMount() {
    this._clearMenuState();
    this._closeMenu();

    window.onclick = (e) => {
      if (e.clientX > 300 && window.innerWidth > 575) {
        this._closeMenu()
      }
    }
  }
  render() {
    const body = document.getElementsByTagName("body")[0];
    const { isMenuOpen, location, newNotifications, userInfo } = this.props
    const username = UserManager.getUserFullName(userInfo)
    const profile = UserManager.getUserProfile(userInfo)

    const animationLinkStyle = isMenuOpen ? {
      opacity: 1,
      zIndex: 1,
      marginLeft: 15,
    } : {}

    if (location.pathname === Routing.login) {
      return <div />
    }

    if (profile === TypeUsers.ADMIN || profile === TypeUsers.SUPER_ADMIN) {
      return (
          <div id="drawer" className="menu-bar flex-column justify-content-start align-items-start">
            <a className="btn-hamburger--close justify-content-center align-items-center" onClick={() => {
              this._closeMenu()
            }} style={{ cursor: 'pointer' }}>
              <span className="material-icons">close</span>
            </a>
            <a className="btn-hamburger justify-content-center align-items-center" onClick={() => {
              this._openMenu()
            }} style={{ cursor: 'pointer' }}>
              <span className="material-icons">menu</span>
            </a>
            <div className="nav">
              {/* nav */}
              <ul className="menu-nav d-flex flex-column justify-content-start align-items-start">
                <li className={location.pathname === Routing.userProfile ? 'active' : ''}>
                  <Link to={Routing.userProfile} className="menu-home mb-2" onClick={() => { this._closeMenu() }}>
                    <span className="material-icons" style={{ fontSize: 40, marginLeft: -4.5 }}>person_outline</span>
                    {isMenuOpen ?
                        <span className="menu-link" style={{
                          opacity: 0, zIndex: -100, width: 150, height: 40,
                          transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                          ...animationLinkStyle
                        }}>{strings('nav.myAccount')}<br /><span style={{ textTransform: 'none' }}>{username}</span></span> : <span />
                    }
                  </Link>
                </li>
                <li className={location.pathname === Routing.dashboard ? 'active' : ''}>
                  <Link to={Routing.dashboard} className="menu-home" onClick={() => { this._closeMenu() }}  id={"panelDashboard"}>
                    <span className="material-icons">home</span>
                    {isMenuOpen ? <span className="menu-link" style={{
                      opacity: 0, zIndex: -100, width: 150,
                      transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                      ...animationLinkStyle
                    }}>{strings('nav.dashboard')}</span> : <span />}
                  </Link>
                </li>
                <li className={location.pathname === Routing.cardList ? 'active' : ''} id={"panelCardList"}>
                  <Link to={Routing.cardList} className="menu-home" onClick={() => { this._closeMenu() }}>
                    <span className="material-icons">credit_card</span>
                    {isMenuOpen ? <span className="menu-link" style={{
                      opacity: 0, zIndex: -100, width: 150,
                      transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                      ...animationLinkStyle
                    }}>{strings('nav.cards')}</span> : <span />}
                  </Link>
                </li>
                <li className={location.pathname === Routing.documents ? 'active' : ''}>
                  <Link to={Routing.documents} className="menu-home" onClick={() => { this._closeMenu() }}>
                    <span className="material-icons">file_present</span>
                    {isMenuOpen ? <span className="menu-link" style={{
                      opacity: 0, zIndex: -100, width: 150,
                      transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                      ...animationLinkStyle
                    }}>{strings('nav.bills')}</span> : <span />}
                  </Link>
                </li>
                <li className={location.pathname === Routing.responsibleConsumption ? 'active' : ''}>
                  <Link to={Routing.responsibleConsumption} className="menu-home" onClick={() => { this._closeMenu() }} id={"panelConsumption"}>
                    <span className="material-icons-outlined">assignment</span>
                    {isMenuOpen ? <span className="menu-link" style={{
                      opacity: 0, zIndex: -100, width: 150,
                      transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                      ...animationLinkStyle
                    }}>{strings('nav.report')}</span> : <span />}
                  </Link>
                </li>
                <li className={location.pathname === Routing.purchaseCards ? 'active' : ''}>
                  <Link to={Routing.purchaseCards} className="menu-home" onClick={() => { this._closeMenu() }} id={"panelPurchaseCards"}>
                    <span className="material-icons-outlined">add_shopping_cart</span>
                    {isMenuOpen ? <span className="menu-link" style={{
                      opacity: 0, zIndex: -100, width: 150,
                      transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                      ...animationLinkStyle
                    }}>{strings('nav.purchaseCards')}</span> : <span />}
                  </Link>
                </li>
                <li className={location.pathname === Routing.notifications ? 'active' : ''}>
                  <Link to={Routing.notifications} className="menu-home" onClick={() => { this._closeMenu() }}>
                    <span className="material-icons">notifications_none</span>
                    {newNotifications > 0 ? <span className="badge rounded-pill badge-notification bg-danger position-absolute mb-3 ml-3">{newNotifications}</span> : <span />}
                    {isMenuOpen ? <span className="menu-link" style={{
                      opacity: 0, zIndex: -100, width: 150,
                      transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                      ...animationLinkStyle
                    }}>{strings('nav.notifications')}</span> : <span />}
                  </Link>
                </li>
                <li className={location.pathname === Routing.cardsPin ? 'active' : ''}>
                  <Link to={Routing.cardsPin} className="menu-home" onClick={() => { this._closeMenu() }}>
                    <span className="material-icons">card_giftcard</span>
                    {isMenuOpen ? <span className="menu-link" style={{
                      opacity: 0, zIndex: -100, width: 150,
                      transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                      ...animationLinkStyle
                    }}>{strings('nav.cardsPin')}</span> : <span />}
                  </Link>
                </li>
                <li className={location.pathname === Routing.costumerSupport ? 'active' : ''}>
                  <Link to={Routing.costumerSupport} className="menu-home" onClick={() => { this._closeMenu() }}>
                    <span className="material-icons">info_outline</span>
                    {isMenuOpen ? <span className="menu-link" style={{
                      opacity: 0, zIndex: -100, width: 150,
                      transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                      ...animationLinkStyle
                    }}>{strings('nav.support')}</span> : <span />}
                  </Link>
                </li>
                {profile === TypeUsers.SUPER_ADMIN?
                    <li className={location.pathname === Routing.backOffice ? 'active' : ''}>
                      <Link to={Routing.backOffice} className="menu-home" onClick={() => { this._closeMenu() }} id={"panelBackoffice"}>
                        <span className="material-icons">settings</span>
                        {isMenuOpen ? <span className="menu-link" style={{
                          opacity: 0, zIndex: -100, width: 150,
                          transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                          ...animationLinkStyle
                        }}>{strings('nav.backOffice')}</span> : <span />}
                      </Link>
                    </li>
                    : <div />
                }
                <li>
                  <a className="menu-home" onClick={() => this._setUserLogout()} style={{ cursor: "pointer" }}>
                    <span className="material-icons">logout</span>
                    {isMenuOpen ? <span className="menu-link" style={{
                      opacity: 0, zIndex: -100, width: 150,
                      transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                      ...animationLinkStyle
                    }}>{strings('nav.logout')}</span> : <span />}
                  </a>
                </li>
              </ul>
            </div>
          </div>
      )

    }

    return (
        <div id="drawer" className="menu-bar flex-column justify-content-start align-items-start">
          <a className="btn-hamburger--close justify-content-center align-items-center" onClick={() => {
            this._closeMenu()
          }} style={{ cursor: 'pointer' }}>
            <span className="material-icons">close</span>
          </a>
          <a className="btn-hamburger justify-content-center align-items-center" onClick={() => {
            this._openMenu()
          }} style={{ cursor: 'pointer' }}>
            <span className="material-icons">menu</span>
          </a>
          <div className="nav">
            {/* nav */}
            <ul className="menu-nav d-flex flex-column justify-content-start align-items-start">
              <li className={location.pathname === Routing.userProfile ? 'active' : ''}>
                <Link to={Routing.userProfile} className="menu-home mb-2" onClick={() => { this._closeMenu() }}>
                  <span className="material-icons" style={{ fontSize: 40, marginLeft: -4.5 }}>person_outline</span>
                  {isMenuOpen ?
                      <span className="menu-link" style={{
                        opacity: 0, zIndex: -100, width: 150, height: 40,
                        transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                        ...animationLinkStyle
                      }}>{strings('nav.myAccount')}<br /><span style={{ textTransform: 'none' }}>{username}</span></span> : <span />
                  }
                </Link>
              </li>
              <li className={location.pathname === Routing.cardList ? 'active' : ''}>
                <Link to={Routing.cardList} className="menu-home" onClick={() => { this._closeMenu() }}>
                  <span className="material-icons">credit_card</span>
                  {isMenuOpen ? <span className="menu-link" style={{
                    opacity: 0, zIndex: -100, width: 150,
                    transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                    ...animationLinkStyle
                  }}>{strings('nav.cards')}</span> : <span />}
                </Link>
              </li>
              <li className={location.pathname === Routing.costumerSupport ? 'active' : ''}>
                <Link to={Routing.costumerSupport} className="menu-home" onClick={() => { this._closeMenu() }}>
                  <span className="material-icons">info_outline</span>
                  {isMenuOpen ? <span className="menu-link" style={{
                    opacity: 0, zIndex: -100, width: 150,
                    transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                    ...animationLinkStyle
                  }}>{strings('nav.support')}</span> : <span />}
                </Link>
              </li>
              <li>
                <a className="menu-home" onClick={() => this._setUserLogout()} style={{ cursor: "pointer" }}>
                  <span className="material-icons">logout</span>
                  {isMenuOpen ? <span className="menu-link" style={{
                    opacity: 0, zIndex: -100, width: 150,
                    transition: 'margin-left .5s ease-out, opacity .2s ease-out',
                    ...animationLinkStyle
                  }}>{strings('nav.logout')}</span> : <span />}
                </a>
              </li>
            </ul>
          </div>
        </div>
    )
  }

  _clearMenuState() {
    this.props.clearMenuState();
  }

  _setUserLogout() {
    this.props.setUserLogout(true)
  }
  _toggleMenuState() {
    const { isMenuOpen } = this.props;
    if (isMenuOpen) {
      this._closeMenu();
    } else {
      this._openMenu();
    }
  }
  _closeMenu() {
    const body = document.getElementsByTagName("body")[0]
    if (this.state.xClickPosition > 300) {
      body.classList.remove('menu-on')
      this.props.setMenuState({ prop: 'isMenuOpen', value: false })

    }
    if (body.classList.contains('menu-on')) {
      body.classList.remove('menu-on')
      this.props.setMenuState({ prop: 'isMenuOpen', value: false })
    }
  }

  _openMenu() {
    const body = document.getElementsByTagName("body")[0]
    if (!body.classList.contains('menu-on')) {
      body.classList.add('menu-on')
      this.props.setMenuState({ prop: 'isMenuOpen', value: true })
    }
  }
}

const mapStateToProps = ({ DrawerReducer, NotificationsReducer, UserReducer }) => {
  const { isMenuOpen } = DrawerReducer;
  const { newNotifications } = NotificationsReducer;
  const { userInfo } = UserReducer;
  return {
    isMenuOpen, newNotifications, userInfo
  }
}

const mapStateToPropsAction = {
  clearMenuState,
  setMenuState,
  setUserLogout
};

export default connect(mapStateToProps, mapStateToPropsAction)(DrawerContainer);
