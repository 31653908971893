import Types from "./Types";
import FormValidationManager from '../../../utils/managers/FormValidationManager';
import { isDev } from '../../../utils/Utils';
import { Auth } from 'aws-amplify';
import { setUserAccessToken, setUserDataProps, setUserInfo, setUserLogout } from '../../user/UserAction';
import LoginManager from './LoginManager';
import { strings } from '../../../resources/locales/i18n.js';
import axios from "axios";
import Config from "react-environment";






// Services
export const apiGetCognitoUser = (redirectSuccess, redirectToConfirmAccount, redirectToCreatePassword, openCodeModal, redirectToRememberPassword) => async (dispatch, getState) => {
  const { username, password } = getState().LoginReducer;
  // --- 1. Validate form
  const error = await FormValidationManager.formLogin({ username, password });
  if (error) {
    return dispatch({
      type: Types.LOGIN_FAILED,
      payload: error
    });
  }
  dispatch(setLoginState({ prop: 'error', value: [] }));
  dispatch(setLoginState({ prop: 'errorRequest', value: '' }));

  // -- 2. Request
  dispatch(setLoginState({ prop: 'isLoadingGetUser', value: true }));
  await Auth.signIn(username.trim(), password.trim())
    .catch((error) => {
      dispatch(responseError(error, redirectToConfirmAccount,redirectToRememberPassword));
    })
    .then((success) => {
      if (success) {
        if (success.challengeName === 'SMS_MFA') {
          if (isDev()) console.log('correct credentials, code sent', success);
          dispatch(setLoginState({ prop: 'cognitoUser', value: success }));
          openCodeModal();
        }
        if (success.challengeName === 'NEW_PASSWORD_REQUIRED') {
          if (isDev()) console.log('need new password', success);
          dispatch(setLoginState({ prop: 'cognitoUser', value: success }));
          redirectToCreatePassword();
        }
        if (success.signInUserSession !== null) { // Si devuelve signInUserSession significa que no hay doble autenticación y se puede coger el token 
          if (isDev()) console.log('[apiGetCognitoUser] successful sign in', success);
          dispatch(responseSuccess(success, redirectSuccess));
        }
      }
    })
  dispatch(setLoginState({ prop: 'isLoadingGetUser', value: false }));
};

export const apiPostLoginFromConfirmation = ({ username, password }, redirectToLogin, redirectToBackOffice) => async (dispatch, getState) => {
  // await redirectToLogin();
  // dispatch(showMessageAfterRedirectInLogin({ prop: 'logoutMessage', value: `Se ha confirmado la cuenta ${username} con éxito` }));

  // LOG IN AFTER REDIRECT -> PROBLEM: IF USER INTRODUCE WRONG PASSWORD AT THE BEGINNING OF PROCESS

  dispatch(setLoginState({ prop: 'isLoadingGetUser', value: true }));
  await Auth.signIn(username.trim(), password.trim())
  .catch(async (error) => {
    await redirectToLogin();
    await dispatch(setLoginState({ prop: 'username', value: username }));
    await dispatch(setLoginState({ prop: 'password', value: password }));
    dispatch(responseError(error));
    })
    .then((success) => {
      if (success) {
        if (isDev()) console.log('[apiPostLoginFromConfirmation] successful sign in', success);
        dispatch(responseSuccess(success, redirectToBackOffice));
      }
    })
  dispatch(setLoginState({ prop: 'isLoadingGetUser', value: false }));
};

export const apiPostLoginWithCode = (redirectSuccess) => async (dispatch, getState) => {
  const { code, cognitoUser } = getState().LoginReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formLoginCode({ code });
  if (error) {
    return dispatch({
      type: Types.LOGIN_FAILED,
      payload: error
    });
  }
  dispatch(setLoginState({ prop: 'error', value: [] }));
  dispatch(setLoginState({ prop: 'errorRequest', value: '' }));

  // -- 2. Request
  dispatch(setLoginState({ prop: 'isLoadingLogin', value: true }));

  if (cognitoUser.challengeName === 'SMS_MFA' ||
    cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
    await Auth.confirmSignIn(
      cognitoUser,   // Return object from Auth.signIn()
      code,   // Confirmation code
      'SMS_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    )
      .catch((e) => {
        dispatch(responseError(e));
      })
      .then((success) => {
        if (success) {
          if (isDev()) console.log('[apiPostLoginWithCode] successful sign in', success);
          dispatch(responseSuccess(success, redirectSuccess));
        }
      })
    dispatch(setLoginState({ prop: 'isLoadingLogin', value: false }));
  }
}


export const apiRefreshToken = (callbackSuccess, callback) => async (dispatch) => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    await cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      /* RefreshToken */
      const { idToken, refreshToken } = session;
      dispatch(setUserAccessToken(idToken.jwtToken, refreshToken.token));

      let config = {
        headers: {
          'Authorization': 'Bearer ' + idToken.jwtToken,
          'Content-Type': 'application/json'
        }
      };
      if (callbackSuccess){
        callbackSuccess(config);
      }

      if (callback){
        callback()
      }
    });
  } catch (e) {
    /* Logout */
    dispatch(setUserLogout(false));
  }
}

export const clearCodeLogin = () => ({
  type: Types.CLEAR_CODE_LOGIN,
});

export const clearDataLogin = () => ({
  type: Types.CLEAR_DATA_LOGIN,
});

export const responseError = (error, redirectToConfirmAccount,redirectToRememberPassword) => async (dispatch) => {
  if (isDev()) console.log(error)

  if (error && error.code && error.message) {

    if (error.code === "UserNotConfirmedException") { // If necessary to confirm user cognito return an error and we redirect to Confirmation view
      redirectToConfirmAccount();
    }

    if (error.code === "PasswordResetRequiredException") {
      redirectToRememberPassword();
    }

    let value = error.message;
    if (error.code === "CodeMismatchException") {
      value = strings('login.error.codeMismatchException')
    }
    if (error.code === "NotAuthorizedException") {
      value = strings('login.error.userDoesNotExist')
    }
    if (error.code === "UserNotFoundException") {
      value = strings('login.error.userDoesNotExist')
    }
    if (error.code === "NetworkError") {
      value = strings('login.error.networkError')
    }

    dispatch(setLoginState({
      prop: 'error',
      value: [{
        key: "request",
        value: value
      }]
    }));
  }
};

const fetchIp = async () => {
  try {
    const responseIP = await axios.get('https://api.ipify.org?format=json');
    let MyIP = responseIP.data.ip.toString();
    if (isDev()) console.log('[fetchIp] IP: ', MyIP);
    const VPN_IPS = process.env.REACT_APP_VPN_IPS.split(',');
    if (isDev()) console.log('[fetchIp] IPs correctas: ', VPN_IPS);
    if(VPN_IPS.includes(MyIP)){
      if (isDev()) console.log('[fetchIp] IP Interna Permitida ', MyIP);
      return true;
    }else{
      if (isDev()) console.log('[fetchIp] Error IP Externa: ', MyIP);
      return false;
    }
  } catch (error) {
    console.error("Error fetching the IP:", error);
    return false;
  }
};

export const responseSuccess = (response, redirectSuccess) => async (dispatch) => {
  if (isDev()) console.log('[responseSuccess] Redirección a web: ', response);
  // Control de acceso por VPN
  if (isDev()) console.log('[responseSuccess] VPN active?: ', Config.REACT_APP_VPN_SUPERADMIN_FILTER);
  if(Config.REACT_APP_VPN_SUPERADMIN_FILTER === 'true'){
    if (isDev()) console.log('[responseSuccess] CheckVPN: ', response.attributes);
    if (response.hasOwnProperty("attributes") && response.attributes.profile === "SuperAdmin"){
      let canAccess = await fetchIp();
      if (isDev()) console.log('[responseSuccess] can Access: ', canAccess);
      if(canAccess){
        dispatch(setUserAccessToken(LoginManager.getUserTokenAmazon(response), LoginManager.getUserRefreshToken(response)));
        dispatch(setUserInfo());
        redirectSuccess();
        dispatch(clearDataLogin());
      }else{
        dispatch(setLoginState({
          prop: 'error',
          value: [{
            key: "request",
            value: "Los usuarios de tipo superAdmin solo pueden acceder desde oficina o por VPN."
          }]
        }));
        dispatch(clearDataLogin());
        alert("Los usuarios de tipo superAdmin solo pueden acceder desde oficina o por VPN.");
      }
    }else{
      if (isDev()) console.log('[responseSuccess] Not SuperAdmin or not attributes. ');
      dispatch(setUserAccessToken(LoginManager.getUserTokenAmazon(response), LoginManager.getUserRefreshToken(response)));
      dispatch(setUserInfo());
      redirectSuccess();
      dispatch(clearDataLogin());
    }
  }else{
    if (isDev()) console.log('[responseSuccess] Not CheckVPN. ');
    dispatch(setUserAccessToken(LoginManager.getUserTokenAmazon(response), LoginManager.getUserRefreshToken(response)));
    dispatch(setUserInfo());
    redirectSuccess();
    dispatch(clearDataLogin());
  }



}

export const setLoginState = ({ prop, value }) => ({
  type: Types.SET_LOGIN_STATE,
  payload: { prop, value }
});

export const showMessageAfterRedirectInLogin = ({ prop, value }) => async (dispatch) => {
  dispatch(
    setLoginState({ prop, value })
  )
  setTimeout(() => dispatch(
    setLoginState({ prop, value: '' })
  ), 4000)
}

export const validateOnBlurLogin = (inputType) => async (dispatch, getState) => {
  const { username, password, error } = getState().LoginReducer;
  let hasError = false
  let errorItem = null
  let indexError = null

  // --- 1. Validate form
  const errorForm = await FormValidationManager.formLogin({ username, password, inputType });

  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }
  // Search the same error in arr
  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError, 1) }
  }

  return dispatch({
    type: Types.LOGIN_FAILED,
    payload: error.slice()
  });
}
