import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import {
  apiDeleteOrg,
  apiDeleteUser,
  apiGetBackOfficeOrgInfo,
  apiGetBackOfficeRequestsInfo,
  apiGetBackOfficeUsersInfo,
  apiGetDownloadOrg,
  apiGetDownloadRequests,
  apiGetDownloadUsers,
  deleteRequestMessage,
  clearBackOfficeInfo,
  setBackOfficeInfoDataState
} from './BackOfficeActions'
import { setUserInfo } from '../user/UserAction';


// Components
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading, ModalContainer, PaginatorContainer, SelectorContainer } from 'src/components';

// Resources
import BackOfficeManager from '../backOffice/BackOfficeManager'
import { changeCurrentLimit, changeCurrentPage } from '../../utils/Utils.js'
import { strings } from '../../resources/locales/i18n.js';
import Routing from '../routing/Routing.jsx';

class BackOfficeContainer extends Component {

  state = {
    isOrgModalShown: false,
    isRequestModalShown: false,
    isUsersModalShown: false,
    key: 0,
    org: null,
    tableName: 'users',
    username: null,
  }

  componentDidMount() {
    this._clearBackOfficeInfo();
    this._apiGetBackOfficeUsersInfo();
    this._apiGetBackOfficeOrgInfo();
    this._apiGetBackOfficeRequestsInfo();
    this._getRandomKey();
    this._getUserInfo();
  }
  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.backOffice'), path: Routing.nowhere },
    ]
    const { isLoadingBackOfficeOrgInfo, isLoadingBackOfficeRequestsInfo, isLoadingBackOfficeUsersInfo, requestMessage } = this.props

    return (
      <>
        <HeaderContainer title={strings('viewName.backOffice')} breadcrumbList={breadcrumbList} />
        <ModalContainer
          closeAction={() => this._toggleUsersModal()}
          icon='delete'
          message={strings('backOfficeView.usersModal.message')}
          modalId='confirmUserDelete'
          isModalShown={this.state.isUserModalShown}
          primaryAction={() => this._apiDeleteUser(this.state.username)}
          primaryButtonText={strings('backOfficeView.usersModal.primaryButton')}
          secondaryAction={() => this._toggleUsersModal()}
          secondaryButtonText={strings('backOfficeView.usersModal.secondaryButton')}
          title={strings('backOfficeView.usersModal.title')}
        />
        <ModalContainer
          closeAction={() => this._toggleOrgModal()}
          icon='delete'
          message={strings('backOfficeView.orgModal.message')}
          modalId='confirmOrgDelete'
          isModalShown={this.state.isOrgModalShown}
          primaryAction={() => this._apiDeleteOrg(this.state.org)}
          primaryButtonText={strings('backOfficeView.orgModal.primaryButton')}
          secondaryAction={() => this._toggleOrgModal()}
          secondaryButtonText={strings('backOfficeView.orgModal.secondaryButton')}
          title={strings('backOfficeView.orgModal.title')}
        />
        <ModalContainer
          closeAction={() => this._toggleRequestModal()}
          icon={requestMessage[0] === 'success' ? 'check' : 'error_outline'}
          message={requestMessage[1]}
          modalId='deleteRequest'
          isModalShown={this.state.isRequestModalShown}
          primaryAction={() => this._toggleRequestModal()}
          primaryButtonText={strings('backOfficeView.requestModal.primaryButton')}
          title={strings('backOfficeView.requestModal.title')}
        />

        <section className="container wrapper wrapper--content pt-5 pb-4">
          {isLoadingBackOfficeUsersInfo || isLoadingBackOfficeOrgInfo || isLoadingBackOfficeRequestsInfo ?
            <div className={'d-flex justify-content-center align-items-center'} style={{ height: 300 }}>
              <Loading color={'#006138'} height={50} width={50} />
            </div> :
            <div>
              <nav className="nav nav-pills">
                <a className={"nav-link py-2 px-3 py-sm-2 px-sm-4 py-md-3 px-md-5 menu-pill " + (this.state.tableName === 'users' ? "pill-active" : "")} onClick={(e) => this.setState({ tableName: 'users' })} id={"tabUsers"}>{strings('backOfficeView.users')}</a>
                <a className={"nav-link py-2 px-3 py-sm-2 px-sm-4 py-md-3 px-md-5 menu-pill " + (this.state.tableName === 'org' ? "pill-active" : "")} onClick={(e) => this.setState({ tableName: 'org' })} id={"tabOrg"}>{strings('backOfficeView.org')}</a>
                <a className={"nav-link py-2 px-3 py-sm-2 px-sm-4 py-md-3 px-md-5 menu-pill " + (this.state.tableName === 'requests' ? "pill-active" : "")} onClick={(e) => this.setState({ tableName: 'requests' })} id={"tabRequest"}>{strings('backOfficeView.requests')}</a>
              </nav>
              <div  id="tableResult">
                {this.state.tableName === 'users' ? this.renderUsersTable() : <div />}
                {this.state.tableName === 'org' ? this.renderOrgTable() : <div />}
                {this.state.tableName === 'requests' ? this.renderRequestsTable() : <div />}
              </div>
            </div>
          }
        </section>
      </>
    )
  }
  renderOrgBodyTable() {
    const { backOfficeOrgData } = this.props;

    if (!backOfficeOrgData) {
      return <div />
    }

    return (
      backOfficeOrgData.map((org, idx) => {

        return (
          <tr key={idx}>
            <th className="align-middle" style={{ textTransform: 'none' }}>{org.name}</th>
            <td className="align-middle text-center">{org.id_sap}</td>
            <td className="align-middle d-flex justify-content-center">
              <button className='btn btn-action' onClick={() => this._toggleOrgModal(org.id_sap)}>
                <span className="material-icons" >delete</span>
              </button>
            </td>
          </tr>
        )
      })
    )
  }
  renderOrgTable() {
    const { backOfficeOrgData, isLoadingGetOrgDownload, orgPagesNum, orgCurrentPage, orgLimit, orgTotalResults } = this.props
    const id = 'org'

    return (
      <>
        <div className="row module-table">
          <div className="col-12 mb-md-0">
            <div className="card p-0">
              <div className="card--hero p-3 d-flex justify-content-between align-items-center">
                <h3 className="title-mid ml-3"/>
                <div className="row">
                  <button
                    className="link-text d-flex align-items-center mr-4"
                    style={{ background: 'none', border: 'none', cursor: 'pointer', outline: 'inherit', padding: 0 }}
                    onClick={() => this._apiGetDownloadOrg()}
                  >
                    {isLoadingGetOrgDownload ? <div className='pr-1'><Loading color={'#006138'} height='16' width='16' /></div>
                      :
                      <span className="material-icons">download</span>
                    }
                    <span className="ml-1"> {strings('responsibleConsumption.downloadReport')} </span>
                  </button>
                  <Link
                    to={Routing.createOrg}
                    id="addButtonTarjeta"
                    className="link-text d-flex align-items-center mr-4"
                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                  >
                    <span className="material-icons mr-1">playlist_add</span>{strings('backOfficeView.orgTable.addOrg')}
                  </Link>
                </div>
              </div>
              {backOfficeOrgData ?
                <div className="table-responsive">
                  <table className="table mb-4">
                    <thead>
                      <tr>
                        <th>{strings('backOfficeView.orgTable.name')}</th>
                        <th className='text-center'>{strings('backOfficeView.orgTable.idSap')}</th>
                        <th className='text-center' >{strings('backOfficeView.orgTable.actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderOrgBodyTable()}
                    </tbody>
                  </table>
                  {/*<div className='d-flex justify-content-between align-items-center'>
                    <SelectorContainer
                      id={id}
                      changeCurrentLimit={changeCurrentLimit}
                      apiGetData={() => this._apiGetBackOfficeOrgInfo()}
                      resultsQuantity={orgLimit}
                      setDataProps={({ prop, value }) => this.props.setBackOfficeInfoDataState({ prop, value })}
                      totalResults={orgTotalResults}
                    />
                    <PaginatorContainer
                      id={id}
                      changeCurrentPage={changeCurrentPage}
                      currentPage={orgCurrentPage}
                      apiGetData={() => this._apiGetBackOfficeOrgInfo()}
                      pagesNum={orgPagesNum}
                      setDataProps={({ prop, value }) => this.props.setBackOfficeInfoDataState({ prop, value })}
                    />
                    <div className='col-4'/>
                  </div>*/}
                </div>
                :
                <div className="title-lite my-3 p-5">{strings('backOfficeView.orgTable.notFound')}</div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  renderRequestsBodyTable() {
    const { backOfficeOrgData, backOfficeRequestsData } = this.props;

    if (!backOfficeRequestsData) {
      return <div />
    }

    return (
      backOfficeRequestsData.map((request, idx) => {
        const cardsNum = BackOfficeManager.getRequestCardsNum(request)
        const date = BackOfficeManager.getRequestDate(request)
        const email = BackOfficeManager.getRequestEmail(request)
        const orderNumber = BackOfficeManager.getRequestOrderNumber(request)
        const orgName = BackOfficeManager.getRequestOrgName(request, backOfficeOrgData)
        const requestType = BackOfficeManager.getRequestType(request)

        return (
          <tr key={idx}>
            <th className="align-middle" style={{ textTransform: 'none' }}>{orderNumber}</th>
            <td className="align-middle">{email}</td>
            <td className="align-middle">{orgName}</td>
            <td className="align-middle">{date}</td>
            <td className="align-middle text-center">{requestType}</td>
            <td className="align-middle text-center">{cardsNum}</td>
          </tr>
        )
      })
    )
  }
  renderRequestsTable() {
    const { backOfficeRequestsData, isLoadingGetRequestsDownload, requestsPagesNum, requestsCurrentPage, requestsLimit, requestsTotalResults } = this.props
    const id = 'requests'

    return (
      <>
        <div className="row module-table">
          <div className="col-12 mb-md-0">
            <div className="card p-0">
              <div className="card--hero py-3 d-flex justify-content-end align-items-center">
                {/* <PaginatorContainer
                  id={id}
                  changeCurrentPage={changeCurrentPage}
                  currentPage={requestsCurrentPage}
                  apiGetData={() => this.props.apiGetBackOfficeRequestsInfo()}
                  pagesNum={requestsPagesNum}
                  setDataProps={({ prop, value }) => this.props.setBackOfficeInfoDataState({ prop, value })}
                /> */}
                <button
                  className="link-text d-flex align-items-center mr-4"
                  style={{ background: 'none', border: 'none', cursor: 'pointer', outline: 'inherit', padding: 0 }}
                  onClick={() => this._apiGetDownloadRequests()}
                >
                  {isLoadingGetRequestsDownload ? <div className='pr-1'><Loading color={'#006138'} height='16' width='16' /></div>
                    :
                    <span className="material-icons">download</span>
                  }
                  <span className="ml-1"> {strings('responsibleConsumption.downloadReport')} </span>
                </button>
              </div>
              {backOfficeRequestsData ?
                <div className="table-responsive">
                  <table className="table m-0">
                    <thead>
                      <tr>
                        <th>{strings('backOfficeView.requestsTable.orderNumber')}</th>
                        <th>{strings('backOfficeView.requestsTable.email')}</th>
                        <th>{strings('backOfficeView.requestsTable.orgName')}</th>
                        <th>{strings('backOfficeView.requestsTable.date')}</th>
                        <th className='text-center'>{strings('backOfficeView.requestsTable.requestType')}</th>
                        <th className='text-center'>{strings('backOfficeView.requestsTable.cardsNum')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderRequestsBodyTable()}
                    </tbody>
                  </table>
                  <div className='d-flex justify-content-between align-items-center'>
                    <SelectorContainer
                      id={id}
                      changeCurrentLimit={changeCurrentLimit}
                      apiGetData={() => this.props.apiGetBackOfficeRequestsInfo()}
                      resultsQuantity={requestsLimit}
                      setDataProps={({ prop, value }) => this.props.setBackOfficeInfoDataState({ prop, value })}
                      totalResults={requestsTotalResults}
                    />
                    <PaginatorContainer
                      id={id}
                      changeCurrentPage={changeCurrentPage}
                      currentPage={requestsCurrentPage}
                      apiGetData={() => this.props.apiGetBackOfficeRequestsInfo()}
                      pagesNum={requestsPagesNum}
                      setDataProps={({ prop, value }) => this.props.setBackOfficeInfoDataState({ prop, value })}
                    />
                    <div className='col-4'/>
                  </div>
                </div>
                :
                <div className="title-lite my-3 p-5">{strings('backOfficeView.orgTable.notFound')}</div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  renderUsersBodyTable() {
    const { backOfficeOrgData, backOfficeUsersData, usersCurrentPage, usersLimit } = this.props;

    if (!backOfficeUsersData) {
      return <div />
    }

    return (
      backOfficeUsersData.map((user, idx) => {
        const phone = BackOfficeManager.getPhoneNumber(user)
        const org = BackOfficeManager.getOrgName(user, backOfficeOrgData)
        const profile = BackOfficeManager.getProfileParse(user)

        return (
          <tr key={idx}>
            <th className="align-middle" style={{ textTransform: 'none' }}>{user.name}</th>
            <td className="align-middle">{user.email}</td>
            <td className="align-middle">{org}</td>
            <td className="align-middle text-center">{profile}</td>
            <td className="align-middle">{phone}</td>
            <td className="align-middle d-flex">
              <Link to={{ pathname: Routing.editUser, params: user }} style={{ textDecoration: 'none' }}>
                <button className="btn btn-action">
                  <span className="material-icons">edit</span>
                </button>
              </Link>
              <button className='btn btn-action' onClick={() => this._toggleUsersModal(user.Username)}>
                <span className="material-icons" >delete</span>
              </button>
            </td>
          </tr>
        )

      })
    )
  }

  renderUsersTable() {
    const { backOfficeUsersData, isLoadingGetUsersDownload, usersPagesNum, usersCurrentPage, usersLimit, usersTotalResults } = this.props
    const id = 'users'

    return (
      <>
        <div className="row module-table">
          <div className="col-12 mb-md-0">
            <div className="card p-0" style={this.state.tableName === 'users' ? { borderRadius: '0px 10px 10px 10px' } : {}}>
              <div className="card--hero p-3 d-flex justify-content-between align-items-center">
                <h3 className="title-mid ml-3"/>
                <div className="row">
                  <button
                    className="link-text d-flex align-items-center mr-4"
                    id={"downloadUsers"}
                    style={{ background: 'none', border: 'none', cursor: 'pointer', outline: 'inherit', padding: 0 }}
                    onClick={() => this._apiGetDownloadUsers()}
                  >
                    {isLoadingGetUsersDownload ? <div className='pr-1'><Loading color={'#006138'} height='16' width='16' /></div>
                      :
                      <span className="material-icons">download</span>
                    }
                    <span className="ml-1"> {strings('responsibleConsumption.downloadReport')} </span>
                  </button>
                  <Link
                    to={Routing.createUser}
                    id="addButtonTarjeta"
                    className="link-text d-flex align-items-center mr-4"
                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                  >
                    <span className="material-icons mr-1">playlist_add</span>{strings('backOfficeView.usersTable.addUser')}
                  </Link>
                </div>
              </div>
              {backOfficeUsersData ?
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{strings('backOfficeView.usersTable.name')}</th>
                        <th>{strings('backOfficeView.usersTable.email')}</th>
                        <th>{strings('backOfficeView.usersTable.org')}</th>
                        <th>{strings('backOfficeView.usersTable.rol')}</th>
                        <th>{strings('backOfficeView.usersTable.phone')}</th>
                        <th>{strings('backOfficeView.usersTable.actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderUsersBodyTable()}
                    </tbody>
                  </table>
                  <div className='d-flex justify-content-between align-items-center'>
                    <SelectorContainer
                      id={id}
                      changeCurrentLimit={changeCurrentLimit}
                      apiGetData={() => this._apiGetBackOfficeUsersInfo()}
                      resultsQuantity={usersLimit}
                      setDataProps={({ prop, value }) => this.props.setBackOfficeInfoDataState({ prop, value })}
                      totalResults={usersTotalResults}
                    />
                    <PaginatorContainer
                      id={id}
                      changeCurrentPage={changeCurrentPage}
                      currentPage={usersCurrentPage}
                      apiGetData={() => this._apiGetBackOfficeUsersInfo()}
                      pagesNum={usersPagesNum}
                      setDataProps={({ prop, value }) => this.props.setBackOfficeInfoDataState({ prop, value })}
                    />
                    <div className='col-4'/>
                  </div>
                </div>
                :
                <div className="title-lite my-3 p-5">{strings('backOfficeView.usersTable.notFound')}</div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
  /* PRIVATE METHOD */

  async _apiDeleteOrg(orgSapId) {
    await this.props.apiDeleteOrg(orgSapId, () => this._toggleRequestModal());
    this._toggleOrgModal();
    this._toggleGlobalAlert();
  }

  async _apiDeleteUser(userId) {
    await this.props.apiDeleteUser(userId, () => this._toggleRequestModal());
    this._toggleUsersModal();
    this._toggleGlobalAlert();
  }

  _apiGetBackOfficeOrgInfo() {
    this.props.apiGetBackOfficeOrgInfo();
  }

  _apiGetBackOfficeRequestsInfo() {
    this.props.apiGetBackOfficeRequestsInfo();
  }

  _apiGetBackOfficeUsersInfo() {
    this.props.apiGetBackOfficeUsersInfo();
  }

  _apiGetDownloadOrg() {
    this.props.apiGetDownloadOrg()
  }

  _apiGetDownloadRequests() {
    this.props.apiGetDownloadRequests()
  }

  _apiGetDownloadUsers() {
    this.props.apiGetDownloadUsers(() => this._toggleGlobalAlert())
  }

  _changeCurrentPage(nextPage) {
    this.props.setBackOfficeInfoDataState({ prop: 'currentPage', value: nextPage })
    this._apiGetBackOfficeRequestsInfo();
  }

  _clearBackOfficeInfo() {
    this.props.clearBackOfficeInfo();
  }

  _deleteRequestMessage() {
    this.props.deleteRequestMessage();
  }

  _closeGlobalAlert() {
    this.setState({ isAlertShown: false })
    this._deleteRequestMessage()
  }

  _getRandomKey() {
    this.setState({ key: Math.random() })
  }
  _getUserInfo() {
    this.props.setUserInfo();
  }

  _handleValueChange = (prop, value) => {
    this.props.setBackOfficeInfoDataState({ prop, value });
  };

  _toggleGlobalAlert() {
    if (this.state.isAlertShown === false) {
      this.setState({ isAlertShown: true })
    }
/*    if (this.state.isAlertShown === true) {
      return; // Don´t close alert if is shown because could be a delete user action repeated, so use _closeGlobalAlert()
    }*/
  }

  _toggleOrgModal(orgSapId) {
    const confirmDelete = document.getElementById('confirmOrgDelete');
    if (confirmDelete.classList.contains('invisible')) {
      confirmDelete.classList.remove('invisible')
      this.setState({ isOrgModalShown: true, org: orgSapId })
    } else {
      confirmDelete.classList.add('invisible')
      this.setState({ isOrgModalShown: false, org: null })
    }
  }
  _toggleRequestModal() {
    const deleteRequest = document.getElementById('deleteRequest');
    if (deleteRequest.classList.contains('invisible')) {
      deleteRequest.classList.remove('invisible')
      this.setState({ isRequestModalShown: true })
    } else {
      deleteRequest.classList.add('invisible')
      this.setState({ isRequestModalShown: false })
    }
  }

  _toggleUsersModal(username) {
    const confirmDelete = document.getElementById('confirmUserDelete');
    if (confirmDelete.classList.contains('invisible')) {
      confirmDelete.classList.remove('invisible')
      this.setState({ isUserModalShown: true, username: username })
    } else {
      confirmDelete.classList.add('invisible')
      this.setState({ isUserModalShown: false, username: null })
    }
  }
}

const mapStateToProps = ({ BackOfficeReducer }) => {
  const {
    backOfficeOrgData,
    backOfficeRequestsData,
    backOfficeUsersData,
    isLoadingBackOfficeDeleteOrg,
    isLoadingBackOfficeDeleteUser,
    isLoadingBackOfficeOrgInfo,
    isLoadingBackOfficeRequestsInfo,
    isLoadingBackOfficeUsersInfo,
    isLoadingGetOrgDownload,
    isLoadingGetRequestsDownload,
    isLoadingGetUsersDownload,
    orgCurrentPage,
    orgLimit,
    orgPagesNum,
    orgTotalResults,
    requestMessage,
    requestsCurrentPage,
    requestsLimit,
    requestsPagesNum,
    usersCurrentPage,
    usersLimit,
    usersPagesNum,
    usersTotalResults,
  } = BackOfficeReducer;

  return {
    backOfficeOrgData,
    backOfficeRequestsData,
    backOfficeUsersData,
    isLoadingBackOfficeDeleteOrg,
    isLoadingBackOfficeDeleteUser,
    isLoadingBackOfficeOrgInfo,
    isLoadingBackOfficeRequestsInfo,
    isLoadingBackOfficeUsersInfo,
    isLoadingGetOrgDownload,
    isLoadingGetRequestsDownload,
    isLoadingGetUsersDownload,
    orgCurrentPage,
    orgLimit,
    orgPagesNum,
    orgTotalResults,
    requestMessage,
    requestsCurrentPage,
    requestsLimit,
    requestsPagesNum,
    usersCurrentPage,
    usersLimit,
    usersPagesNum,
    usersTotalResults,
  }
}

const mapStateToPropsAction = {
  apiDeleteOrg,
  apiDeleteUser,
  apiGetBackOfficeOrgInfo,
  apiGetBackOfficeRequestsInfo,
  apiGetBackOfficeUsersInfo,
  apiGetDownloadOrg,
  apiGetDownloadRequests,
  apiGetDownloadUsers,
  deleteRequestMessage,
  clearBackOfficeInfo,
  setBackOfficeInfoDataState,
  setUserInfo
};
export default connect(mapStateToProps, mapStateToPropsAction)(BackOfficeContainer);
